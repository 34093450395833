import React, { useState, useRef, useEffect } from "react";

import { useSessionStorageState } from "ahooks";
import * as styles from "./index.module.scss";
import { consumerRequest, uploadFileRequest } from "@src/utils/formrequest";

import addIcon from "@src/images/complaints/addIcon.png";

import { Link } from "gatsby";

import { Input, Form, Upload, Button, Col, Row, Modal, message as showMessage } from "antd";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Header from "@components/header";

import Footer from "@components/footer";

const Consumers = () => {
  const data = useStaticQuery(graphql`
    query {
      formBanner: file(relativePath: { eq: "complaints/formBanner.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallFormBanner: file(
        relativePath: { eq: "complaints/smallFormBanner.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      noticeImg: file(relativePath: { eq: "complaints/noticeImg.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 170) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      smallNoticeImg: file(
        relativePath: { eq: "complaints/smallNoticeImg.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      repeatNoticeImg: file(
        relativePath: { eq: "complaints/repeatNoticeImg.png" }
      ) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 170) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const normFile = (e) => {
    console.log("Upload event:", e);
    const { file, fileList } = e;
    if (file.size / 1024 / 1024 > 10) {
      //如果大小大于10M
      fileList.pop()
      showMessage.error("上传的文件不能超过10M")
    }
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  //modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRepeatModalVisible, setRepeatModalVisible] = useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible();
  };

  //上传文件
  const [uploadFile, setUploadFile] = useState([]);

  //表单提交
  const onFinish = (value) => {
    const role = "消费者";
    console.log("onfinish>>>>>>>>>>", value);
    const {
      name,
      email,
      phone_number,
      infringement_target,
      infringement_url,
      added,
    } = value;

    consumerRequest({
      file: uploadFile,
      name,
      email,
      phone: phone_number,
      shop_url: infringement_target,
      commodity_url: infringement_url,
      remark: added,
      role,
    }).then(
      (res) => {
        //上传表单成功后根据返回的状态码来渲染ui组件

        console.log("上传表单成功后后端返回的数据>>>>>>>>", res);
        const {
          data: { resultCode, message },
        } = res;
        console.log("表单上传成功后的状态码>>>>>>", resultCode);
        console.log("表单上传成功后返回的信息>>>>>>", message);

        if (resultCode === 200) {
          setFields(null);
          setIsModalVisible(true);
        } else if (resultCode === 205) {
          setFields(null);
          setRepeatModalVisible(true);
        } else {
          // console.log("发生了其他意料之外的错误");
          showMessage.error(message)
        }
      },
      (err) => {
        // console.log("上传表单失败", err);
        showMessage.error('上传表单失败，', err)
      }
    );
  };

  //上传文件
  const customRequest = ({ file, onSuccess, onError }) => {
    if (file.size / 1024 / 1024 < 10) {
      //文件小于10M才上传
      uploadFileRequest(file).then(
        (res) => {
          onSuccess(res, file);
          console.log('上传文件中《《《《《《《');
          setUploadFile([...uploadFile, res.data.data.path]);
        },
        (err) => {
          onError(err, file);
        }
      );
    }

  };

  //数据回显功能使用ahooks 实现
  const [fields, setFields] = useSessionStorageState();
  const onFieldsChange = (_, allFields) => {
    setFields(allFields);
    // console.log(fields);
  };

  useEffect(() => {
    return () => {
      setFields(undefined);
    };
  }, []);

  const consumers = useRef();
  return (
    <div className={styles.consumers} ref={consumers}>
      <Header />

      {/* 表单 */}
      <div className={styles.formBanner}>
        <Row>
          <Col xs={0} sm={0} md={24}>
            <Img fluid={data.formBanner.childImageSharp.fluid} />
            <h1>消费者投诉登记表</h1>
            <h4>
              填写人需保证所填信息真实有效，
              Shoprise将在收到反馈后3-7个工作日以邮件形式回复您。
            </h4>
          </Col>
          <Col xs={24} sm={24} md={0}>
            <Img fluid={data.smallFormBanner.childImageSharp.fluid} />
            <h1>消费者投诉登记表</h1>
            <h4>
              填写人需保证所填信息真实有效，
              <br />
              Shoprise将在收到反馈后3-7个工作日以邮件形式回复您。
            </h4>
          </Col>
        </Row>
      </div>
      <div className={styles.formContainer}>
        <Form
          layout="vertical"
          onFinish={onFinish}
          onFieldsChange={onFieldsChange}
          fields={fields}
        >
          {/* 用户名字 */}
          <Form.Item
            label="姓名"
            name="name"
            rules={[{ required: true, message: "请输入投诉人姓名" }]}
          >
            <Input placeholder="请填写投诉人姓名" />
          </Form.Item>
          {/* 用户邮箱 */}
          <Form.Item
            label="邮箱"
            name="email"
            rules={[
              { required: true, message: "请输入投诉联系人邮箱" },
              { type: "email", message: "请输入正确形式的邮箱" },
            ]}
          >
            <Input placeholder="请填写投诉人联系邮箱，用于后续回复" />
          </Form.Item>
          {/* 用户电话 */}
          <Form.Item
            label="电话"
            name="phone_number"
            rules={[
              { required: true, message: "请输入投诉联系人电话" },
              {
                pattern:
                  /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/,
                message: "请输入正确的手机号",
              },
            ]}
          >
            <Input placeholder="请填写投诉人联系电话，用于后续回复" />
          </Form.Item>

          {/* 投诉的店铺地址 */}
          <Form.Item
            label="投诉的店铺地址"
            name="infringement_target"
            rules={[
              {
                required: true,
                message: "请输入投诉的店铺地址",
              },
              {
                type: "url",
                message: "请输入正确的店铺url地址",
              },
            ]}
          >
            <Input placeholder="请填写投诉的店铺地址" />
          </Form.Item>

          {/* 投诉作品/商品的链接 */}
          <Form.Item
            label="投诉作品/商品的链接"
            name="infringement_url"
            rules={[
              {
                required: true,
                message: "请输入投诉作品/商品的链接",
              },
            ]}
          >
            <Input.TextArea placeholder="请填写投诉作品/商品的链接，可填写多个，每个地址换行" />
          </Form.Item>

          {/* 其他材料补充 */}
          <Form.Item
            label="其他材料补充"
            name="another-material"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            className={styles.upload}
            extra={
              <div className={styles.anotherIntro}>
                上传能证明此网站侵权的材料证据（比如：图片，视频等，文件必须少于10MB）
              </div>
            }
            rules={[
              {
                required: false,
                message: "请上传其他材料",
              },
            ]}
          >
            <Upload.Dragger name="files"
              customRequest={customRequest}
            >
              <img src={addIcon} alt="addIcon" />
              {/* <span className={styles.addFile}>+</span> */}
            </Upload.Dragger>
          </Form.Item>
          <Form.Item
            label="补充说明"
            name="added"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input.TextArea placeholder="可在此补充说明这些证据是如何证明该网站违规" />
          </Form.Item>
          {/* 提交按钮 */}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                width: "100%",
                height: "60px",
                fontSize: "18px",
                fontWeight: "bold",
                borderRadius: "8px",
                marginTop: "40px",
              }}
            >
              提交
            </Button>
          </Form.Item>
        </Form>
        {/* <Button type="primary" onClick={showModal}>
          Open Modal
        </Button> */}

        {/* 上传成功的modal */}
        <Modal
          visible={isModalVisible}
          getContainer={consumers.current}
          closable={false}
          onOk={handleOk}
          centered
          onCancel={handleCancel}
          maskClosable={false}
          footer={
            <div className={styles.modalFooter}>
              <Button
                className={styles.buttonStyle}
                onClick={() => {
                  setIsModalVisible(false);
                }}
              >
                <Link to="/complaints/">确定</Link>
              </Button>
            </div>
          }
          className={styles.modal}
        >
          <div className={styles.imgContainer}>
            <Img fluid={data.noticeImg.childImageSharp.fluid} />
          </div>
          <div className={styles.submitSuccess}>提交成功</div>
          <div className={styles.greatfulMessage}>
            感谢您的反馈，我们会在审核资料后给您回复，请耐心等待。
          </div>
        </Modal>

        {/* 重复提交的modal */}
        <Modal
          visible={isRepeatModalVisible}
          getContainer={consumers.current}
          closable={false}
          centered
          maskClosable={false}
          onCancel={() => { setRepeatModalVisible(false) }}
          footer={
            <div className={styles.modalFooter}>
              <Button
                className={styles.buttonStyle}
                onClick={() => {
                  setRepeatModalVisible(false);
                }}
              >
                <Link to="/complaints/">确定</Link>
              </Button>
            </div>
          }
          className={styles.modal}
        >
          <div className={styles.imgContainer}>
            <Img fluid={data.repeatNoticeImg.childImageSharp.fluid} />
          </div>
          {/* <div className={styles.submitSuccess}>您已经提交成功，无须再次提交</div> */}
          <div
            className={styles.greatfulMessage}
            style={{
              lineHeight: "80px",
            }}
          >
            您已经提交成功，无须再次提交
          </div>
        </Modal>
      </div>
      <Footer />
    </div>
  );
};

export default Consumers;

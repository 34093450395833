// extracted by mini-css-extract-plugin
export var addFile = "index-module--addFile--XUCaZ";
export var anotherIntro = "index-module--anotherIntro--lRi8a";
export var buttonStyle = "index-module--buttonStyle--NZk2G";
export var consumers = "index-module--consumers--AniZ-";
export var formBanner = "index-module--formBanner--G90yG";
export var formContainer = "index-module--formContainer--jkNlK";
export var greatfulMessage = "index-module--greatfulMessage--aHcmB";
export var imgContainer = "index-module--imgContainer--9qF4K";
export var modal = "index-module--modal--98-zz";
export var modalFooter = "index-module--modalFooter--faM6S";
export var submitSuccess = "index-module--submitSuccess--lW4hb";
export var upload = "index-module--upload--FsjMq";